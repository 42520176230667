import React, { useState } from 'react'
import { createContext } from 'react'

export const Context = createContext()

const ContextProvider = ({children}) => {

const [state,setState]=useState(false)
const [scrollStatus, setScrollStatus] = useState("");
const [user,setUser]=useState([])
const [openDepositModal, setOpenDepositModal]=useState(false)
const [openProfileEditModal,setOpenProfileEditModal]=useState(false)
const [openCreditUserBalanceUi,setOpenCreditUserBalanceUi]=useState(false)
const [openAllUsersModal,setOpenAllUsersModal]=useState(false)

const check = ''



  return (
    <Context.Provider value={{state,scrollStatus,setScrollStatus,user,setUser,openDepositModal, 
    setOpenDepositModal,openProfileEditModal,setOpenProfileEditModal,
    openCreditUserBalanceUi,setOpenCreditUserBalanceUi,openAllUsersModal,setOpenAllUsersModal}}>
        {children}
    </Context.Provider>
  )
}

export default ContextProvider




//salimkhamis563@gmail.com // new email for paypal , made for the client
// pw :   sk5123sk5
