import React, { useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom'

const PayPalButton = ({ paypalAmount,accountNumber }) => {
  const navigate = useNavigate();
  const paypalRef = useRef();

  const clientid = "ATbZWnQPtN92C7JSvyZ49Qi9Y-5E571scEwxjsqoxfbp-vTuegC67g-m5Dx081k-P99I_Y9JfqsMnLSp" //est client id on paypal


  useEffect(() => {
    // Dynamically load the PayPal script
    const loadPayPalScript = async () => {
      if (!window.paypal) {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientid}&currency=USD`;
        script.async = true;
        script.onload = () => initializePayPalButton();
        document.body.appendChild(script);
      } else {
        initializePayPalButton();
      }
    };

    const initializePayPalButton = () => {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: paypalAmount, // Payment amount

                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            try {
              const order = await actions.order.capture();
              console.log('Order captured:', order);
              // onSuccess(order); // Pass the order details to the success callback
              handleCreditUser();
            } catch (err) {
              console.error('Error capturing order:', err);
              // onError(err); // Pass the error to the error callback
            }
          },
          onError: (err) => {
            console.error('PayPal Checkout Error:', err);
            // onError(err); // Handle error
          },
        })
        .render(paypalRef.current);
    };

    loadPayPalScript();
  }, [paypalAmount]);


  //crediting user on success
   const handleCreditUser = async () => {       
  
          Swal.fire({
              title: 'Crediting...',
              allowOutsideClick: false,
              didOpen: () => {
                  Swal.showLoading();
              }
          });
  
          try {
              const response = await fetch('https://meritnationalbank.com/api/manual_credit.php', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: new URLSearchParams({
                      account_number: accountNumber,
                      amount: paypalAmount,
                  }),
              });
  
              const data = await response.json();
  
              Swal.close();
  
              if (data.success) {
                  Swal.fire('Success!', data.message);
                  // navigate("/dashboard")
                  
              } else {
                  Swal.fire('Error!', data.message);
              }
          } catch (error) {
              Swal.fire('Error!', 'Something went wrong. Please try again.');
          }
  
      };





  return (
    <div style={{
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      position:"fixed",
      zIndex:"999",
      top:"50px",
      // width:"100%",
      left:"10%",
      backgroundColor:"white",
      // width:"100%",
      height:"100%",
      flexDirection:"column",
      padding:"10px",
      gap:"20px"

  }}>
      <h3 style={{textAlign:"center", color:"rgba(0,0,255,0.7)"}}>PayPal Checkout</h3>
      <div ref={paypalRef}></div>
      <button onClick={()=>window.location.reload() }
        style={{
          padding:"10px",
          border:"none",
          backgroundColor:"rgba(0,0,255,0.5)",
          color:"white",
          cursor:"pointer"
        }}>Cancel</button>
    </div>
  );
};

export default PayPalButton;
